import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileServiceService {

    constructor(
        private http: HttpClient,
    ) { }

    public get(): Observable<any> {
        return this.http.get<any>(environment.REST_URL + '/users');
    }

    public update(data): Observable<any> {
        return this.http.put<any>(environment.REST_URL + '/users', data);
    }
}
